import React, { useState, useEffect } from "react";
import ErrorMessage from "./ErrorMessage";

const FileUpload = ({ errorMessage, handleModal, handleUploadFile, setFileList, active, setConvert }) => {

    const VIDEOFORMATS = ['mp4', 'mkv', 'mov', 'avi', 'wmv', 'flv', 'webm', 'm4v', 'mpeg', 'mpg', '3gp'];

    const KILO_BYTES_PER_BYTE = 1000;

    const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

    const [files, setFiles] = useState({});

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [showConvertCheckbox, setShowConvertCheckbox] = useState(false);

    useEffect(() => {
        if (!active) {
            setFiles({});
            setIsButtonDisabled(true);
            setShowConvertCheckbox(false);
        }
    }, [active, setFiles]);


    const changeHandler = (e) => {
        const {files: newFiles} = e.target;

        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            console.log(Object.entries(updatedFiles))
            setFileList(Object.values(updatedFiles));
            setIsButtonDisabled(false);
        }
    }

    const addNewFiles = (newFiles) => {
        let extensions = new Set();
        for (let file of newFiles) {
            files[file.name] = file;
            extensions.add(file.name.split(".").pop().toLowerCase());
        }
        setShowConvertCheckbox(VIDEOFORMATS.some(value => extensions.has(value.toLowerCase())));

        return { ...files };
    }

    const removeFile = (fileName) => {
        delete files[fileName];
        setFiles({ ...files });
        setFileList(Object.values(files));
        for (let file of Object.values(files)) {
            if (VIDEOFORMATS.includes(file.name.split(".").pop().toLowerCase())) {
                return;
            }
        }
        setShowConvertCheckbox(false);
    }

    const handleConvert = (e) => {
        setConvert(e.target.checked);
    }

    return (
        <>
            <header className="modal-card-head has-background-primary-light">
                <h1 className="modal-card-title">Upload Files</h1>
            </header>
            <section className="modal-card-body">
                <div className="columns">
                    <div className="ml-6">
                        <form>
                            <>
                                <div className="field">
                                    <div className="control">
                                        <label className="label">Files:</label>
                                            {Object.keys(files).map((fileName, index) => {
                                                let file = files[fileName];
                                                return (
                                                    <section key={fileName}>
                                                        <div>
                                                            <div>
                                                                <span>{fileName}</span>
                                                                <aside>
                                                                    <div style={{cursor: "pointer", maxWidth: "120px"}}
                                                                        onClick={() => removeFile(file.name)}>
                                                                        <span>{convertBytesToKB(file.size)} kb </span>
                                                                        <i className="fa fa-ban" aria-hidden="true"></i>
                                                                    </div>
                                                                </aside>
                                                            </div>
                                                        </div>
                                                    </section>
                                                )
                                            })}
                                    </div>
                                </div>
                            </>
                            {showConvertCheckbox && (
                                <div className="field">
                                    <div className="control">
                                        <label className="checkbox">
                                            <input id="convert-checkbox" type="checkbox" defaultChecked={false} onChange={handleConvert} />
                                            Convert with ffmpeg
                                        </label>
                                    </div>
                                </div>
                            )}
                            <div className="field">
                                <div className="control">
                                    <div className="file has-name is-boxed">
                                        <label className="file-label">
                                            <input
                                                className="file-input"
                                                type="file"
                                                name="file"
                                                title=""
                                                value=""
                                                onChange={changeHandler}
                                                accept="image/*, .pdf, .mov, .mp4, .watchface, .mkv, .mov, .avi, .wmv, .flv, .webm, .m4v, .mpeg, .mpg, .3gp, .zip, .watchcraft, .string, .STRING, .festiva, .json, .plist"
                                                multiple
                                            />
                                            <span className="file-cta">
                                                <span className="file-icon">
                                                    <i className="fas fa-upload"></i>
                                                </span>
                                                <span className="file-label">Choose file(s)…</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <ErrorMessage message={errorMessage} />
            <footer className="modal-card-foot has-background-primary-light">
                <button disabled={isButtonDisabled} className="button is-info" style={{ cursor: "pointer" }} onClick={handleUploadFile}>
                    Upload
                </button>
                <button className="button" style={{ cursor: "pointer" }} onClick={handleModal}>
                    Cancel
                </button>
            </footer>
        </>
    );
};

export default FileUpload;
