import { useEffect } from "react";

const ProductDetails = (props) => {

    const showCopiedText = () => {
        const textSpan = document.getElementById("copied-message");
        textSpan.style.display = 'block'
        setTimeout(() => {
            textSpan.style.display = 'none';
        }, 1300);
    }

    const changeVideoSource = (source) => {
        const videoSource = document.getElementById("video-card-content");
        videoSource.setAttribute("src", source);
    }

    const handlePlayer = () => {
        props.setSourceUrl(props.currentItem.url);
        props.setVideoPlayerActive();
    }

    useEffect(() => {
        if (props.currentItem.url && ["mp4", "mov"].includes(props.currentItem.url.split(".").pop().toLowerCase()) ) {
            // changeVideoSource(props.currentItem.url);
        }
    })



    return (
        <div className="card">
            <button type="button" onClick={() => props.resetCurentItem()} className="btn-close close-card" aria-label="Close"></button>
            <div className="card-content">
                <div>
                    <figure>
                        {props.currentItem.url && ["mp4", "mov"].includes(props.currentItem.url.split(".").pop().toLowerCase()) ? (
                            <>
                                {/* <video id="video-card-content" loop style={{ maxWidth: "128px" }}>
                                    <source src={props.currentItem.url} type="video/mp4" />
                                </video> */}
                                <div>
                                    <button type="button" className="button is-info btn btn-default" onClick={handlePlayer}>Play Video</button>
                                </div>
                            </>
                        ) : ( ["gif", "png", "jpg", "jpeg", "webp", "svg", "JPG", "watchface", "watchcraft" , "string", "STRING", "festiva", "plist", "json"].includes(props.currentItem.url.split(".").pop()) &&
                            <img src={props.currentItem.url} alt={props.currentItem.text} style={{ maxWidth: "128px" }} />
                        )}
                    </figure>
                </div>
                <div className="content">
                    <>
                        <div className="field">
                            <div className="control">
                                <label className="label">File name:</label>
                                <a href={props.currentItem.url} target="_blank" rel="noopener noreferrer">
                                    {props.currentItem.text}
                                </a>
                            </div>
                        </div>
                        <div className="field">
                            <div
                                className="control"
                                onClick={() => {
                                    navigator.clipboard.writeText(props.currentItem.url);
                                    showCopiedText();
                                }}
                            >
                                <label className="label">
                                    Url:{" "}
                                    <span className="tooltip">
                                        &#9432; <span className="tooltiptext">Click to copy</span>
                                    </span>
                                </label>
                                <span id="copied-message" style={{display: 'none'}}>Copied!</span>
                                <p style={{ wordBreak: "break-all", fontSize: "14px", cursor: "pointer" }}>{props.currentItem.url}</p>
                            </div>
                        </div>
                    </>
                    {/* <div className="field">
                        <div className="control">
                            <label className="label">Access token: </label>
                            <p style={{ wordBreak: "break-all" }}>{props.currentItem.access_token}</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ProductDetails;
